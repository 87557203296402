interface Props {
    setPage: (x:string) => void
}

export default function AdvancedConfigPage(props:Props) {
    return (
        <>
        <p>In addition to the UPDeck configuration, there are additional settings that can be configured in the iOS Settings app. 
        <b>In most cases, these ssettings should be left as default.</b>
        </p>

        <p>There are also settings to map control buttons to functionality in the iOS Settings app.  These settings are designed for 
            interacting with UPDeck and can be considered obselete.  While you can still map controller buttons and button combinations to 
            functionality in the settings, if those buttons or button combinations are configured in a Scene Collection, the collection actions 
            will be the ones which execute.
        </p>
        <p>In addition to assigning single events to controller buttons, there are also Macros, which are consecutive actions you can set to 
            execute on a single button press. 
        </p>
        </>
    )}