import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}
export default function TouchControlsPage(props:Props) {
    return (
        <>


        <h4>Zoom in/out</h4>
        <p>Swipe up on the camera preview to zoom in, and swipe down to zoom out.  The speed of the swipe will control how fast the camera zooms in and out.</p>
        <h4>Clock / Timer Control</h4>
       
        <p> <img src='images/clock_normal.png' style={{width:'50px'}}/>Tap the stopwatch icon to start or stop the game clocks.  The background of the app will change from red (clock stopped) to green (clock running) 
            as a visual cue to the clock state.
        </p>
       
        <p> <img src='images/shotclock_normal.png' style={{width:'50px'}}/>Tap the reset icon to reset the shot clock</p>
       
        <p> <img src='images/clockadjust_normal.png' style={{width:'50px'}}/>Swipe right or left on the timer icon to select which timer to adjust.  The name of the timer (for example, "clock", "shotclock" or "homepenaltyclock" will appear on the screen.</p>
        <p>Swipe up or down on the timer icon to add or removed time from the selected timer.</p>
        <h4>Instant Replay</h4>
        <p> <img src='images/replay_normal.png' style={{width:'50px'}}/>Tap once on the instant replay icon to start the replay buffer.  The replay status icon will turn white, indicating the replay buffer is active.</p>
        <p>When the repllay status icon is white, tap the replay icon for an instant replay.  The replay will play in a window in the bottom right corner.</p>
        <p>To cancel a running replay, tap the replay icon while it is running.  The replay will stop and disappear.</p>
        <h4>Menus</h4>
        <p> <img src='images/control_normal.png' style={{width:'50px'}}/>Long press the control icon to bring up the Game Action menu.</p>
        <p>With the game menu active, swipe right for the Setup Action menu, and right again for the Scene selection menu. </p>
        <p>Swipe up or down to choose menu items</p>
        <p>Long press on the control icon to select the current menu item.</p>
        <p> <img src='images/cancel_normal.png' style={{width:'50px'}}/>Press the cancel icon to cancel the current menu selection.</p>
        <h4>Camera Controls</h4>
        <p> <img src='images/focus_normal.png' style={{width:'50px'}}/>Tap the focus icon to lock the camera focus.  The icon will turn yellow, indicating the focus is locked.  Tap it again to unlock focus.</p>
        <p> <img src='images/exposure_normal.png' style={{width:'50px'}}/>Tap the exposure icon to lock the camera exposure.  The icon will turn yellow, indicating the exposure is locked.  Tap it again to unlock the exposure.</p>
        <img src='images/camerascreenshot.PNG' style={{width:'50%', border:'2px solid black'}}/>
        </>

    )}