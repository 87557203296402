import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";


export default function ControllerReference() {
    return (
        <>


<Accordion>
            <AccordionSummary>Open Controller Quick Reference</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            <img src='images/xbox-front.jpg' style={{width:'50%'}} />
            <img src='images/xbox-back.jpg' style={{width:'50%'}} />
            <ul>
                <li>Zoom camera in / out - Right Thumbstick up/down</li>
                <li>Start / Stop Game Clock - Right Trigger (11)</li>
                <li>Reset Shot Clock - Left Trigger (14)</li>
                <li>Instant Replay - Direction Pad right (8) and Y button</li>
                <li>Increase / Decrease Game Clock - Left Thumbstick up/down (1)</li>
                <li>Increase / Decrease Shot Clock - Left Thumbstick right/left (1)</li>
                <li>Increment Home Team shot count - Left Shoulder Button (2)</li>
                <li>Increment Away Team shot count - Right Shoulder Button (7)</li>
                <li>Decrement Home Team shot count - Direction Pad down (8) and Left Shoulder Button (2)</li>
                <li>Decrement Away Team shot count - Direction Pad down (8) and Right Shoulder Button (7)</li>
                <li>Game Menu - X button</li>
                <li>Scene Menu - Menu Button (6)</li>
                <li>Setup Menu - Options Button (3)</li>
                <li>Select Current Item - A button</li>
                <li>Back / Cancel - B button</li>
                <li>Clear all penalties - Right Thumbstick button</li>
                <li>Clear Home Penalties - Direction Pad Left (8) and Right Thumbstick Button</li>
                <li>Clear Away Penalties - Direction Pad Right (8) and Right Thumbstick Button</li>
                <li>Clear Text - Direction Pad Down (8) and Right Thumbstick Button</li>
                <li>Select and Show Theme Image - Direction Pad Left (8) and X button</li>
                <li>Select and Show Theme Video - Direction Pad Right (8) and X button</li>
                <li>Clear Theme Image - Direction Pad Down (8) and X button</li>

            </ul>
            </AccordionDetails>
        </Accordion>

        </>
    )}