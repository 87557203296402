import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}
export default function SceneCollectionsPage(props:Props) {
    return (<><h1>Scene Collections</h1>
    
    <p>Scene Collections define the scenes available for a stream or recording.  Each supported sport will have a 
    scene collection containing the scoreboard overlay and related graphics, as well as additional scenes that can be
    displayed during the stream.
    </p>

    <p>Additional scene collections can be downloaded to the app by tapping "Load Collection from URL" and entering the URL 
        of the collection.  Additional collection URLs will be made available on this site.
    </p>

    <p>Scene collections can be customized with team logos and colors via the Theme settings screen.</p>

    <h3>Scene Collection Sharing</h3>
    <p>You can transfer themed scene collections between phones, to share with others or to a backup phone to stream with.</p>
    <p>To share a collection, open the Scene Collection page on both phones, and tap the "Enable Sharing" button on both phones.  This will enable sharing mode for 5 minutes.  Then, on the phone with the scene collection you want
        to transfer, tap the "Share" button.  The other phone will prompt to allow the content transfer - tap "Allow" on that phone, then after giving the phones a few seconds to connect, click "Continue" on the first phone to send 
        the scene collection to the second phone.
    </p>
    
    <img src='images/settingsscreenshot.PNG' style={{width:'50%', border:'2px solid black'}}/>
    
    </>
    )}