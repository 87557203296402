import { Typography } from "@mui/material";
import { NAV_BETA } from "../Constants";
import YouTubeEmbed from "../Components/YouTube";
import { useRef } from "react";


interface Props {
    setPage: (x:string) => void
}
export default function HomePage(props:Props) {
  

    const clips = ["https://www.youtube.com/embed/1FlX8c7xudQ?si=1Zrg1qIQo0k98tS7&amp;clip=Ugkxi_5lR9_5YZtqgbcMIFRPBVAlWoN77Vu_&amp;clipt=EMzIKxjfty4&autoplay=1&mute=1",
    "https://www.youtube.com/embed/1FlX8c7xudQ?si=zJ25YuNSHO-7Hy2L&amp;clip=UgkxDM_3nrPbQnwAbzFRrcU3tlUHIsgLn22D&amp;clipt=EODzTBjk2E8&autoplay=1&mute=1",
    "https://www.youtube.com/embed/0pOBovSdphk?si=ffjtTXAJLclPl_Xn&amp;clip=UgkxrW1HJZWX9rCE9o0UCBfBODGArbjk-jz-&amp;clipt=ELCJyAEYzf_JAQ&autoplay=1&mute=1",
    "https://www.youtube.com/embed/QETS5SgZ2Lg?si=i-IK7RqrNbw2M_x3&amp;clip=UgkxiKXiTP5Ypt1eu449YWRMOm5TuZ5kTn2W&amp;clipt=EI3-0gEY5oLVAQ&autoplay=1&mute=1",
    "https://www.youtube.com/embed/Pru15YJkvLM?si=tLgjzikwnTMCqdeN&amp;clip=Ugkxi_d2IguUPkY56khPj98QBw-wCE8A8GgY&amp;clipt=EP3eswEY4Pm1AQ&autoplay=1&mute=1"
    ]

    let clipurl:string = clips[Math.floor(Math.random() * clips.length)];


    
    return (
        <>
        <p>SportStream Camera is an iOS app designed for live streaming amateur sports.  It aims to provide a better live streaming and viewing experience for fans and family who can't be at the event in person.</p>
        
        <h4>SportStream Camera - Example Stream</h4>
<YouTubeEmbed url={clipurl}></YouTubeEmbed>

      
        <p>While there are many options for live streaming amateur sporting events, few of those options produce an engaging, informative and entertaining viewing experience.  Too often, live streams are frustrating to watch, leaving 
            viewers wondering what the score is, how much time is left, and who those tiny players on the screen are.  SportStream Camera was designed to provide a better viewing experience by 
            maximizing the potential of the iPhone to produce broadcast quality live streams, without investing in expensive equipment.
        </p>
        <h3>Goals</h3>
        <ul>
            <li>Provide the best live stream for viewers who can't be there in person</li>
            <li>Produce broadcast quality live streams with just an iPhone</li>
            <li>Make quality live streaming an accessible, affordable and fun experience</li>
        </ul>


        <h3>Features</h3>
        <ul>
            <li>Live Streaming via RTMP protocol (YouTube, Facebook Live, etc)</li>
            <li>Customizable score graphics</li>
            <li>Game Controller integration</li>
            <li>Instant Replay</li>
            <li>Live stream resolutions from 480p to 1080p</li>
            <li>Game recording</li>
            <li>Teams and Rosters</li>
        </ul>
        <hr/><a href="/privacy">Privacy Policy</a>
        </>
    )}