import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ControllerReference from "../Components/ControllerReference";

interface Props {
    setPage: (x:string) => void
}
export default function GameControllerPage(props:Props) {
    return (
        <><h1>Using a Game Controller</h1>

<ControllerReference />


        <p>The app has been designed to use a game controller, such as an Xbox One or PS4 bluetooth controller, to zoom the camera 
            as well as control the score and related components. While the app can be used without a controller, for best results and 
            usability it is recommended to use a game controller.
        </p>

        <p>Each scene collection will have it's own controls, however there are some controls which apply to all scene collections.</p>


        <p>The right thumbstick on the controller is used to zoom the camera in and out,  by moving the thumstick up (to zoom in) or down 
            (to zoom out).  The zoom speed is controlled by how far the thumbstick is moved in the up or down direction.</p>
<p>There are three menus to choose actions from when using the game controller.  When selecting a menu item, the up and down direction pad keys 
    are used to scroll through the options, and the "A" button is used to select the option.  To cancel out of a menu selection, 
    use the "B" button.
</p>
<p>To enter the game action menu, press the "X" button on the controller.  This menu will include options to change the score, add penalties, 
    reset the time clock, set the period/inning, etc.</p>

        <p>To switch to a different scene in the current collection, press the "menu" button.  This will activate the scene selection 
            menu, which will allow you to switch to another scene in the current collection.</p>
            <p>The third menu is the setup menu.  To activite, press the "options" button.  The setup menu will allow you to set the 
                teams, and start or stop streaming or recording.
            </p>
            <p>To connect a game controller, pair it to your phone using bluetooth.  The app will recognize a paired and connected controller and 
                automatically switch to controller mode.</p>

                <h4>Multiple Controllers</h4>
                <p>The SportStream Camera app supports up to two connected controllers.  When a second controller is connected, it can not control the camera zoom, but can 
                    control all other functions, such as tracking shots, entering goals/penalties, switching scenes, etc.</p>

        </>
    )}