




interface Props {
    embedId?: string;
    startTime?: string;
    url?: string;


    
  }





export default function YouTubeEmbed(props:Props) {


    var url = `https://www.youtube.com/embed/${props.embedId}?start=${props.startTime}`
    if(props.url) {
        url = props.url!
    }

    return (
    <div className="video-responsive">
    <iframe
      width="640"
      height="360"
      
      src={url}
      frameBorder="3"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="SportStream Camera Stream Example"
    />
  </div>
    )
    
}
