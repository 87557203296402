import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}
export default function YouTubePage(props:Props) {
    return (
        <>
        <p>SportStream Camera integrates with YouTube to retrieve scheduled live streams and manage the URL and stream keys for you.</p>
        
        <p>When you navigate to the Stream page, the app will prompt you to log in with your Google/YouTube account.  Once you are logged in 
            the schedule streams will be available to select on the stream page.  Selecting a stream will populate the URL and Stream Key 
            fields with the appropriate values for that stream.
        </p>

        <p>Scheduling the streams must be done ahead of time in <a href='https://studio.youtube.com' target='_blank'>YouTube Studio.</a> The app will 
        only allow you to select existing streams.  Instructions on how to schedule a live stream can be found on <a href='https://support.google.com/youtube/answer/2907883?hl=en' target='_blank'>Google's site.</a></p>

        <p>Once the stream is scheduled on YouTube, you can share the link with your audience.</p>
        </>
    )}