

interface Props {
    setPage: (x:string) => void
}
export default function AboutPage(props:Props) {
    return (
        <>
        <p>COVID-19 cut short my daughters’ ringette season in March 2020, and by the time the next season started in October the pandemic was in full swing. One of the many conditions of organized sports in Saskatchewan was that only one spectator (parent) was allowed in the rink to watch the games.
</p>
<p>While there were options for streaming (LiveBarn was being deployed in hundreds of locations, and solutions like Facebook Live allowed anyone to stream the games) I wanted to provide the best viewing experience for live streams as I could, for those who could not be at every game. While the aforementioned options were OK, I felt there was still a big gap between them and watching in person.
</p>
<p>With what I had available (a MacBook and a bunch of old iPhones and some various digital cameras) I settled on using OBS Studio as a platform to stream with. I tried several different camera options but ultimately the iPhone was the simplest solution, and had the highest quality of what I had available. Simply plugging the camera into the Mac, and opening OBS was enough to show whatever was on the iPhone screen in OBS.
</p>
<p>I struggled to find an app that would do what I needed – simply show the camera preview on the screen without any controls or information (or watermarks) overlayed, so I decided to build a very simple app that showed the camera preview full screen. This was the start of the SportStream Camera app.
</p>
<p>Over the next few years, through ringette and softball seasons, I was able to stream my daughters’ games live, and worked on improving the streams (adding things like instant replay, player information, enhanced scoreboards, and in game information). As my streams got more advanced, I found UPDeck – a fantastic piece of software which allowed me to control OBS from a single screen on a spare iPhone I had, making it much quicker to add things like shot counts to the games. But as the streams became more evolved, I found that moving my hands and eyes between the camera, the laptop, and the UPDeck app on the phone was a little more than I could handle.
</p>
<p>I had played around with trying to connect a simple bluetooth controller (that came with a VR headset we found at a bargain store) to the app to control the camera zoom, but after the second game with that controller I left it at a rink in Spruce Grove (about 9 hours from home) so I had to go back to the drawing board.
</p>
<p>I was trying to figure out what I could replace that with when I decided to try the Xbox controller. Once the zoom was working, it became apparent to me that this was the way to go – if I could replace the UPDeck screen with the controller buttons, I could manage my stream without needing my eyes, allowing me to focus on the camera work with my eyes, and control OBS via the game controller.
</p>
<p>After a few iterations I had something that worked very well for what I needed. I realized that perhaps this could have value for others as well, so I refactored the app to be more configurable, and released the first official version of SportStream Camera.
</p>     
        </>
    )}


