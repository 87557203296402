import { Typography } from "@mui/material";

interface Props {
  setPage: (x:string) => void
}

export default function FacebookPage(props:Props) {
    return (
      <>
      <p>Facebook Live supports RTMPS streaming.  To use Sportstream Camera with Facebook Live, you will need to follow the instructions <a href='https://www.facebook.com/business/help/165076674943644?id=1123223941353904' target='_blank'>provided by Facebook.</a></p>
      
      <p>Copy the URL and stream key from Facebook to the URL and Stream Key fields on the Stream page.</p>

      <p><b>Note that the Go Live buttons on the Stream page do not apply to Facebook Live.</b> You will need to set the stream to live from the Facebook website/app.</p>
      
      </>
    )}