interface Props {
    setPage: (x:string) => void
}
export default function TermsOfService(props:Props) {
    return (
        <>
       <h2>TERMS OF SERVICE AGREEMENT</h2>

<p>This Terms of Service Agreement ("Agreement") is entered into by and between the user ("User" or "You") and SportStream Camera ("Company," "We," "Us," or "Our"). This Agreement governs the use of the SportStream Camera iOS application (the "App"), which facilitates live streaming of video and audio content via Real-Time Messaging Protocol (RTMP), specifically designed for amateur sporting events.
</p>
<p>By downloading, installing, or using the App, You agree to be bound by the terms and conditions of this Agreement. If You do not agree to these terms, please do not use the App.
</p>
<p><b>1. Grant of License:</b></p>
<p>1.1 Subject to the terms of this Agreement, We grant You a limited, non-exclusive, non-transferable, and revocable license to use the App for the purpose of live streaming amateur sporting events.
</p>
<p><b>2. User Responsibilities:</b></p>
<p>2.1 You are solely responsible for the content You stream using the App.</p>
<p>2.2 You must comply with all applicable laws and regulations while using the App.</p>
<p>2.3 You agree not to use the App for any unlawful, harmful, or fraudulent activities.</p>

<p><b>3. Content Ownership:</b></p>
<p>3.1 You retain ownership of all intellectual property rights in the content You stream using the App.</p>
<p>3.2 By using the App, You acknowledge that We do not use the streamed content. However, We may collect analytical and diagnostic information to improve the App.</p>

<p><b>4. Prohibited Activities:</b></p>
<p>4.1 You shall not reverse engineer, decompile, or disassemble the App.</p>
<p>4.2 You shall not interfere with the operation of the App or attempt to gain unauthorized access to its features.</p>

<p><b>5. Privacy and Data Security:</b></p>
<p>5.1 We take the privacy and security of Your data seriously. Our Privacy Policy, available on our website, outlines how We collect, use, and protect Your personal information.</p>
<p>5.2 The App does not record streams. When streaming to third-party providers such as YouTube, the content is no longer under the control of the App.</p>

<p><b>6. Termination:</b></p>
<p>6.1 We reserve the right to terminate or suspend Your access to the App at any time and for any reason.</p>
<p>6.2 Upon termination, You must cease using the App, and all licenses granted to You under this Agreement shall immediately terminate.</p>

<p><b>7. Disclaimers:</b></p>
<p>7.1 The App is provided "as is" without any warranty of any kind.</p>
<p>7.2 We do not guarantee the uninterrupted, timely, or error-free operation of the App.</p>

<p><b>8. Limitation of Liability:</b></p>
<p>8.1 To the fullest extent permitted by law, We shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use of the App.</p>

<p><b>9. Governing Law:</b></p>
<p>9.1 This Agreement shall be governed by and construed in accordance with the laws of Saskatchewan, Canada.</p>

<p><b>10. Subscription Costs:</b></p>
<p>10.1 Access to certain features may require additional recurring subscription costs, as outlined in the App's subscription terms.</p>

<p><b>11. Changes to the Agreement:</b></p>
<p>11.1 We reserve the right to modify or update this Agreement at any time. Changes will be effective upon posting on the App or our website.</p>

<p>By using the App, You acknowledge that You have read, understood, and agree to be bound by this Terms of Service Agreement. If You have any questions or concerns, please contact Us at [Your Contact Email].</p>

<p>Last updated: January 6, 2024</p>

        </>
    )
}