import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ControllerReference from "../Components/ControllerReference";
import { NAV_STREAM } from "../Constants";

interface Props {
    setPage: (x:string) => void
}
export default function RingetteHockeyPage(props:Props) {
    return (<><h1>Ringette/Hockey</h1>

    <p>Features and functions that are specific to the Ringette and Hockey Scene Collections can be found below.  Click on each heading to view details.</p>
    
   

    <Accordion>
            <AccordionSummary>Scenes</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
                <p>There are three built in scenes to choose from when using the Ringette or Hockey Collections.  </p>

                    
                <h3>"Main" Scene</h3>
                <p>This scene contains a full score board, including clock, shot clock (for Ringette), penalty clocks, shot counts, score and goal/assist/penalty announcements.</p>
                <img src="images/mainscreen.png" style={{width:'50%', border:'2px solid black'}}/>
            
                <h3>"Simple" Scene</h3>
                <p>This scene contains the team names, score, and goal/assist/penalty announcements.  It does not contain any clocks.</p>
                <img src="images/simplescreen.png" style={{width:'50%', border:'2px solid black'}}/>

                <h3>"Intermission" Scene</h3>
                <p>This scene includes a summary of the score and shots, intended to be used before the game, between periods, and at the end of the game.</p>
                <img src="images/intermissionscreen.png" style={{width:'50%', border:'2px solid black'}}/>
            
            </AccordionDetails>
    </Accordion>

    <Accordion>
            <AccordionSummary>Options</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
                <p>You can customize the behaviour and look of the Ringette and Hockey collections (and their themed collections) on the Options screen.  The following options are available:</p>
               <ul>
               <li>Use Shot Clock (Ringette Collection)
                    <blockquote>When enabled, the shot clock will appear on the "main" screen scoreboard. </blockquote>
                </li>
                <li>Reset Shot Clock on Clock Start/Stop (Ringette Collection)
                    <blockquote>When enabled, the shot clock will reset whenever the game clock is stopped or started. </blockquote>
                </li>
                <li>Reset Shot Clock on Shot Counted (Ringette Collection)
                    <blockquote>When enabled, the shot clock will reset whenever a shot is added.</blockquote>
                </li>
                <li>Delete Replay Files
                    <blockquote>When enabled, replay videos will automatically be deleted.  If not enabled, replay videos will be saved in the iOS "Files" app.</blockquote>
                </li>
                 <li>Replay Time in Seconds
                    <blockquote>This will control how far back (in real time) a replay video will go.  Replays play at 50% speed, so the actual time the replay will appear on screen will be double this value.</blockquote>
                </li>
                <li>PeriodName
                    <blockquote>This will be used on the Intermission screen.  For example, if this is set to "Qaarter" the Intermission screen will show "End of First Quarter" after the first period.</blockquote>
                </li>

               </ul>
               
                <img src="images/options.png" style={{width:'50%', border:'2px solid black'}}/>

            </AccordionDetails>
    </Accordion>

    <Accordion>
            <AccordionSummary>Custom Player Graphics</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
                <p>The app will display any theme images or videos that are named with a player number, when a goal or penalty is entered for the "Us" team (the first team selected when choosing teams).  This feature will not work for the "Them" team.</p>
                <img src="images/playerimage.png" style={{width:'50%', border:'2px solid black'}}/>
                <p>Note that iOS will sometimes convert .png images to .jpg to save space on the phone, but this conversion eliminates the transparancy.  If your transparent images show with a white background, this is most likely the case.  To reduce 
                    the chances of this happening, you can disable the "Optimize iPhone Storage" option in the iOS Settings app under Photos.
                     </p>
            </AccordionDetails>
    </Accordion>

    <Accordion>
            <AccordionSummary>Replay Graphics</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
                <p>To display a theme image during a replay, save it in the theme with the name "replay".  The app will show the image during an instant replay.</p>
                <p>If you have multiple images named "replay" the app will choose one at random to display.</p>
                <p>This can be used to display sponsors' logos, etc.</p>
                <img src="images/replaygraphic.png" style={{width:'50%', border:'2px solid black'}}/>

            </AccordionDetails>
    </Accordion>

<ControllerReference />

<Accordion>
            <AccordionSummary>Startup Checklist</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
                <p>To get the app set up for a game, the following steps should be taken:</p>
                <ul>
                    <li>Open the SportStream Camera app</li>
                    <li>Load the Scene Collection to use</li>
                    <li>Tap the "Menu" button on the controller, and select the "Main" scene to show the scoreboard</li>
                    <li>Tap the "Options" button to open up the Game Setup menu, and select "Set Teams".  Choose the teams, with your team selected first.</li>
                    <li>Tap the "X" button to open the the Game Action Menu, and choose "Reset Clock" to set the clock minutes.</li>
                    <li>Hold the "right" direction on the direction pad and tap "Y" to start the replay buffer</li>
                    
                    </ul>

                    <p>Once you have the app set up to follow the game, refer to the steps to <a href="#" onClick={()=>{props.setPage(NAV_STREAM)}}>start streaming</a>.</p>
            </AccordionDetails>
    </Accordion>


    <Accordion>
            <AccordionSummary>Game Logs</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
                <p>SportStream Camera will generate a text log containing game events such as goals and penalties.  This log file will be saved in the iOS Files app, under the SportStream Camera folder.</p>
                <p>You can disable the game log in the iOS Settings app under the SportStream Camera section.</p>
                <p>Game logs are named with the date and team names, and will include both the game clock time, and the stream time of each event, so you can find the events in the stream easily.</p>
               
<p>Below is an example game log:</p>
                <img src="images/gamelog.png" style={{width:'50%', border:'2px solid black'}}/>

            </AccordionDetails>
    </Accordion>



    </>
    )}