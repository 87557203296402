import { Typography } from "@mui/material";
interface Props {
    setPage: (x:string) => void
}
export default function OBSPage(props:Props) {
    return (
        <>

        <p>SportStream Camera was originally developed to be a camera source for OBS.</p>

        <p>You can connect the iPhone to OBS (via usb) and use the screen capture method to capture the app screen (cropped to the camera portion) 
            as a video source in OBS.   This will allow you to make use of as many features of the SportStream Camera app as you like: zooming, scoreboards, 
            replays, etc.
        </p>

        <p>When connecting to OBS, it is recommended to set the camera resolution and framerate to the highest settings that support 
            phase autofocus mode, to maintain the highest video quality in OBS.
        </p>

        <p>Any other streaming software that accepts the iPhone screen mirroring as a video source should also work.</p>
       
        </>
    )}