

export const NAV_SETTINGS = 'Settings';
export const NAV_AUDIO = 'Audio';
export const NAV_THEMES = 'Theme';
export const NAV_FACEBOOK = 'Facebook';
export const NAV_GAMECONTROLLER = 'Game Controller';
export const NAV_RECORDING = 'Recording';
export const NAV_SCENECOLLECTION = 'Scene Collection';
export const NAV_SPEEDTEST = 'Speed Test';
export const NAV_TEAMS = 'Teams';
export const NAV_TOUCHCONTROL = 'Touch';
export const NAV_YOUTUBE = 'YouTube';
export const NAV_CAMERA = 'Camera';
export const NAV_HOME = 'SportStream Camera'
export const NAV_OBS = 'Streaming with OBS'
export const NAV_UPDECK = 'UPDeck'
export const NAV_QUICKSTART = "Quick Start"
export const NAV_ABOUT = 'About SportStream Camera'
export const NAV_PRIVACY = 'Privacy Policy'
export const NAV_STREAM = 'Live Streaming'
export const NAV_CONFIG = 'Advanced Configuration'
export const NAV_AGENT = 'UPDeck Connection Agent'
export const NAV_OPTIONS = 'Collection Options'

export const NAV_CONTROLS = 'Collection Controls'
export const NAV_BETA = 'Beta Test'
export const NAV_TOS = 'Terms of Service'
export const NAV_ISSUES = 'Known Issues'

export const NAV_RINGETTE = "Ringette / Hockey"




