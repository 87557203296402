import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}
export default function CustomThemesPage(props:Props) {
    return (
        <><h1>Customizing Scene Collections</h1>
        <p>Some scene collections can be customized with a team logo, colors and fonts.  The "Theme" screen can be used to 
            customize a scene collection, preview each scene in the collection, and save a themed collection as a new scene collection.
            This allows the same base collection to be used for multiple teams, each with a custom theme.
        </p>
        <p>To customize a scene collection, choose it in the "Collections" view, then go to the "Themes" view.  Select the scene you want to 
            preview, then tap on the color selections to set the theme colors. The preview will update with the selected colors.
        </p>
        <p>To set a custom logo, tap the "Logo" button and choose the logo from the images on the phone.  For best results, use a transparent
            image (such as a .png).  Once the logo is selected, you can adjust the position of the logo by tapping the "Adjust Logo Position" button, 
            then using the sliders to update the logo position for each scene.
        </p>
        <p>To update the font used for the collection, select an available font from the font selection spinner.</p>
        <p>To temporarily hide the theme color/font/scene selectors, tap the "Hide View" button.   The controls will disappear, allowing you to preview the entire view.</p>
        
<h3>Theme Images</h3>
<p>You can add images to a themed collection to display on screen.  These images can be selected manually to display, or in some cases they will display automatically.</p>
<p>All images should be 1920x1080 .png images with transparency.  Any images that are displayed will be resized to fit the entire display.</p>
<p>Once you select a theme image from your photo library, you will need to name it.  The name is used to select which display to show when choosing an image on the stream.</p>

<h3>Theme Videos</h3>
<p>As with images, you can add videos to a themed collection, selecting them from your photo library.</p>
<p>Videos will play full screen, and any pure green pixels will not be rendered.</p>
<p>The threshold for green pixels which will be hidden can be set in the iOS Settings app under the SportStream Camera settings.  It is a value between 0 and 1.  Higher values will hide more 
    pixels, increasing the threshold for transparency.  You can use this setting to adjust the app for your specific videos.
</p>


        <p>Once you have configured the theme, you can tap the "Save" button to save that themed collection under a new name.  This collection will 
            now be available on the Collections view
        </p>

        <img src="images/theme_screenshot.png" style={{width:'50%', border:'2px solid black'}}  />

        </>
    )}