import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, ListSubheader } from '@mui/material';
import { useEffect, useState } from 'react';
import SettingsPage from '../Pages/Settings';
import SceneCollectionsPage from '../Pages/SceneCollections';
import CustomThemesPage from '../Pages/CustomThemes';
import AudioPage from '../Pages/Audio';
import CameraSettingsPage from '../Pages/CameraSettings';
import FacebookPage from '../Pages/Facebook';
import GameControllerPage from '../Pages/GameController';
import RecordingPage from '../Pages/Recording';
import SpeedTestPage from '../Pages/SpeedTest';
import TeamsPage from '../Pages/Teams';
import TouchControlsPage from '../Pages/TouchControl';
import YouTubePage from '../Pages/YouTube';
import { NAV_ABOUT, NAV_AGENT, NAV_AUDIO, NAV_BETA, NAV_CAMERA, NAV_CONFIG, NAV_CONTROLS, NAV_FACEBOOK, NAV_GAMECONTROLLER, NAV_HOME, NAV_ISSUES, NAV_OBS, NAV_OPTIONS, NAV_PRIVACY, NAV_QUICKSTART, NAV_RECORDING, NAV_RINGETTE, NAV_SCENECOLLECTION, NAV_SETTINGS, NAV_SPEEDTEST, NAV_STREAM, NAV_TEAMS, NAV_THEMES, NAV_TOS, NAV_TOUCHCONTROL, NAV_UPDECK, NAV_YOUTUBE } from '../Constants';
import HomePage from '../Pages/Home';
import UpDeckPage from '../Pages/Updeck';
import OBSPage from '../Pages/OBS';
import QuickStartPage from '../Pages/QuickStart';
import AboutPage from '../Pages/About';
import PrivacyPage from '../Pages/Privacy';
import StreamingPage from '../Pages/Streaming';
import AdvancedConfigPage from '../Pages/AdvancedConfig';
import AgentPage from '../Pages/Agent';
import BetaTestPage from '../Pages/Beta';
import OptionsPage from '../Pages/Options';
import ControlsPage from '../Pages/Controls';
import TermsOfService from '../Pages/TOS';
import IssuesPage from '../Pages/Issues';
import MenuIcon from "@mui/icons-material/Menu";
import { Label } from '@mui/icons-material';
import RingetteHockeyPage from '../Pages/Ringette_Hockey';

const drawerWidth = 140;

export default function PermanentDrawerLeft(startPage:string) {

const [page,setPage] = useState(startPage)
const [dialogOpen,setDialogOpen] = useState(false)
const [menuOpen,setMenuOpen] = useState(false)
const [leftPos,setLeftPos] = useState('0px')

useEffect(()=>{},[leftPos])

const closeMenu = ()=>{setLeftPos('0px');setMenuOpen(false);}
const openMenu = ()=>{setLeftPos('140px');setMenuOpen(true);}

//useEffect(()=>{setPage(NAV_HOME)},[])


  return (
    <>
    <Box sx={{ display: 'flex' }}>
     
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{ width: '100%',  backgroundColor: 'black', height:'60px' }}
      >
        <Button sx={{left:'0px',top:'15px',position:'fixed'}} onClick={()=>{openMenu()}}>
          <MenuIcon></MenuIcon>
         Menu
        </Button>
        <Toolbar sx={{left:'60px'}}>
          <img src='images/sportstream_logo.png'style={{height:'50px',top:'0px'}} />
          &nbsp;&nbsp;<h3 style={{color:'white'}}>{page}</h3>
         
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          top:'60px',
          marginTop:'60px'
        }}
        variant="persistent"
        anchor="left"
        open={menuOpen}
        
      >
       
        <Divider />
        
        <Accordion expanded={false}>
        <AccordionSummary sx={{height:'60px',backgroundColor:'black'}}><Button sx={{left:'0px',top:'15px',position:'fixed'}} onClick={()=>{closeMenu()}}>
          <MenuIcon></MenuIcon>
        </Button></AccordionSummary>
        </Accordion>
     
        <Accordion expanded={false}>
        <AccordionSummary onClick={(event)=>{setPage(NAV_HOME); closeMenu()}}>Home</AccordionSummary>
        </Accordion>
        <Accordion expanded={false}>
        <AccordionSummary onClick={(event)=>{setPage(NAV_QUICKSTART); closeMenu()}}>Quick Start</AccordionSummary>
        </Accordion>
        <Accordion expanded={false}>
        <AccordionSummary onClick={(event)=>{setPage(NAV_RINGETTE); closeMenu()}}>Ringette / Hockey</AccordionSummary>
        </Accordion>

      <Accordion>
        <AccordionSummary onClick={() => {setPage(NAV_SETTINGS)}}>Settings</AccordionSummary>
        <AccordionDetails>
        <List>
      
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_SCENECOLLECTION); closeMenu()}}>
                <ListItemText primary="Scene Collections" />
              </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_TEAMS); closeMenu()}}>
            
                <ListItemText primary="Teams" />
              </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_THEMES); closeMenu()}}>
              
                <ListItemText primary="Custom Themes" />
              </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_STREAM); closeMenu()}}>
               
                <ListItemText primary="Stream" />
              </ListItemButton>
        </ListItem>

      
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_CAMERA); closeMenu()}}>
              
                <ListItemText primary="Camera Settings" />
              </ListItemButton>
        </ListItem>


        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_AUDIO); closeMenu()}}>
               
                <ListItemText primary="Audio" />
              </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_OPTIONS); closeMenu()}}>
              
                <ListItemText primary="Collection Options" />
              </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_CONTROLS); closeMenu()}}>
               
                <ListItemText primary="Collection Controls" />
              </ListItemButton>
        </ListItem>
       
        </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary onClick={()=>{setPage(NAV_STREAM)}}>Streaming</AccordionSummary>
        <AccordionDetails>
          <List> <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_SPEEDTEST); closeMenu()}}>
              
                <ListItemText primary="Speed Test" />
              </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_YOUTUBE); closeMenu()}}>
              
                <ListItemText primary="YouTube" />
              </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_FACEBOOK); closeMenu()}}>
             
                <ListItemText primary="Facebook Live" />
              </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_RECORDING); closeMenu()}}>
               
                <ListItemText primary="Recording" />
              </ListItemButton>
        </ListItem></List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Usage / Controls</AccordionSummary>
        <AccordionDetails>
          <List> 
            <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_GAMECONTROLLER); closeMenu()}}>
              
                <ListItemText primary="Game Controller" />
              </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_TOUCHCONTROL); closeMenu()}}>
              
                <ListItemText primary="Touch Controls" />
              </ListItemButton>
        </ListItem></List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>Advanced</AccordionSummary>
        <AccordionDetails>
          <List> 

          <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_OBS); closeMenu()}}>
               
                <ListItemText primary="OBS" />
              </ListItemButton>
        </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_UPDECK); closeMenu()}}>
               
                <ListItemText primary="UPDeck Integration" />
              </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_CONFIG); closeMenu()}}>
             
                <ListItemText primary="Advanced Configuration" />
              </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_AGENT); closeMenu()}}>
            
                <ListItemText primary="UPDeck USB Connection Agent" />
              </ListItemButton>
        </ListItem>


      </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>About</AccordionSummary>
        <AccordionDetails>
          <List> 

          <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_ABOUT); closeMenu()}}>
             
                <ListItemText primary="Story" />
              </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_PRIVACY); closeMenu()}}>
              
                <ListItemText primary="Privacy Policy" />
              </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
              <ListItemButton onClick={() => {setPage(NAV_TOS); closeMenu()}}>
              
                <ListItemText primary="Terms Of Service" />
              </ListItemButton>
        </ListItem>


        </List>
        </AccordionDetails>
        </Accordion>

       
<br/><br/>
<center>
    <Button onClick={()=>{setDialogOpen(true); }}><img src='images/download.png' style={{width:'75%'}} /></Button>

    </center>
        
      
      </Drawer>
     
      
    </Box>
    <Box
    component="main"
    sx={{marginLeft:`${leftPos}`,flexGrow: 1, bgcolor: 'background.default', p: 3 }}
  >

    {page == NAV_SETTINGS && SettingsPage({setPage})}
    {page == NAV_SCENECOLLECTION && SceneCollectionsPage({setPage})}
    {page == NAV_THEMES && CustomThemesPage({setPage})}
    {page == NAV_AUDIO && AudioPage({setPage})}
    {page == NAV_CAMERA && CameraSettingsPage({setPage})}
    {page == NAV_FACEBOOK && FacebookPage({setPage})}
    {page == NAV_GAMECONTROLLER && GameControllerPage({setPage})}
    {page == NAV_RECORDING && RecordingPage({setPage})}
    {page == NAV_SPEEDTEST && SpeedTestPage({setPage})}
    {page == NAV_TEAMS && TeamsPage({setPage})}
    {page == NAV_TOUCHCONTROL && TouchControlsPage({setPage})}
    {page == NAV_YOUTUBE && YouTubePage({setPage})}
    {page == NAV_HOME && HomePage({setPage})}
    {page == NAV_UPDECK && UpDeckPage({setPage})}
    {page == NAV_OBS && OBSPage({setPage})}
    {page == NAV_QUICKSTART && QuickStartPage({setPage})}
    {page == NAV_ABOUT && AboutPage({setPage})}
    {page == NAV_PRIVACY && PrivacyPage({setPage})}
    {page == NAV_STREAM && StreamingPage({setPage})}
    {page == NAV_CONFIG && AdvancedConfigPage({setPage})}
    {page == NAV_AGENT && AgentPage({setPage})}
    {page == NAV_OPTIONS && OptionsPage({setPage})}
    {page == NAV_CONTROLS && ControlsPage({setPage})}
    {page == NAV_TOS && TermsOfService({setPage})}
    {page == NAV_ISSUES && IssuesPage({setPage})}
    {page == NAV_RINGETTE && RingetteHockeyPage({setPage})}










    
    
  </Box>
  <Box>Copyright 2024 - SportStream Software</Box>
  </>
  );
}