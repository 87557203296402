import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}
export default function AudioPage(props:Props) {
    return (
        <>
      

        <p>The Audio screen will show the current input and output (monitoring) audio devices.  You can use the internal microphone of the iPhone, 
            or you can connect another microphone source to the iPhone to use.  
        </p>

        <p>To prevent feedback, the iPhone speaker can not be used to monitor the audio that is being sent to the stream.  You can connect headphones (wired or bluetooth) to 
            hear the audio being recorded.  
        </p>

        <p>iOS will decide how to route the input and output audio based on the connected accessories.  If you have an accessory conected that supports both audio 
            input and audio output, the app will use those as the recording and monitoring sources.
        </p>

        <img src='images/audio_screenshot.png' style={{width:'50%', border:'2px solid black'}}  />
        </>
    )}