import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}

export default function CameraSettingsPage(props:Props) {
    return (
        <>
        <h1>Camera Settings</h1>
        <p>The camera settings view allows you to set the camera resolution, frame rate, color space, focus mode as well as toggle the 
            stabilization feature on or off.</p>
        <p>When recording, the recording quality will be determined by the camera settings.  </p>
        <p>Streaming quality is not determined by the camera settings, however the camera settings for resolution 
            and frame rate should be at least the resolution and frame rate you are streaming at. 
        </p>
        <p>Different iPhones will support different combinations of resolution, frame rate and focus mode.  For best results, 
            choose the highest resolution and frame rate that support the phase focus mode.  Contrast focus is not recommended for 
            live streaming, as it will refocus every few seconds, causing the stream to go blurry and refocus.
        </p>
        <p>The app will save the last selected camera mode between streams.  You do not have to set the camera mode each time you run 
            the app - it will only need to change when you want to change the camera settings.
        </p>
        <p>The Camera screen will show the max zoom factor.  Lower max zooms indicate that the selected mode is not available for all cameras on the iPhone, 
            and the quality may be reduced when zooming in.  It is recommended that you compare the video quality when using the zoom between available formats 
            to select the one that is best suited for your needs.
        </p>

<img src='images/camerascreenshot.PNG' style={{width:'50%', border:'2px solid black'}} />

        </>
    )}