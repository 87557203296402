import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, Typography } from "@mui/material";
import SpeedTestPage from "./SpeedTest";
import { bgcolor } from "@mui/system";
import SceneCollectionsPage from "./SceneCollections";
import YouTubePage from "./YouTube";
interface Props {
    setPage: (x:string) => void
}
export default function QuickStartPage(props:Props) {
    return (
        <><h1>Quick Start Guide</h1>
        
        <p>To broadcast a Live Stream to YouTube, the following steps are necessary.  To access the app settings, tap twice 
            on the gear icon in the top right corner
        </p>

        <Accordion>
            <AccordionSummary>Configure the Live Stream using YouTube Studio</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            {YouTubePage({setPage:props.setPage})}
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary>Run a speed test</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
                {SpeedTestPage({setPage:props.setPage})}
            
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary>Choose a scene collection</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            {SceneCollectionsPage({setPage:props.setPage})}
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary>Select a scene</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            <p>Use the game controller or tap controls to open the Scene menu, and select one of the available scenes.</p>
            <p>Scenes are graphic overlays to represent the score, intermission summaries, etc.  Each collection will have it's 
                own scenes to choose from.</p>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary>Set the teams, and the game clock (if necessary)</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            <p>Use the game controller or tap controls to open the Setup menu, and select "Choose Teams" to set the home and 
                away teams.  Teams and rosters that have been added to the app will be available for all Scene Collections.
            </p>
            <p>If you are streaming a game with a game clock, you can set the game clock from the Game menu.  Use the game controller or tap 
                controls to enter the Game menu, then select "Reset Clock".
            </p>
            </AccordionDetails>
        </Accordion>
      
      
        <Accordion>
            <AccordionSummary>Log in using your YouTube account</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            <p>When you navigate to the "Stream" settings page the first time, the app will prompt you to log in to your YouTube/Gmail 
                account.  Once logged in, your available YouTube streams will be listed on the "Stream" settings page to choose from.
            </p>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary>Select the stream to broadcast</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            <p>Once your available streams have been retrieved, you can scroll through them to set the proper Stream URL and Stream Key.</p>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary>Set the target bitrate according to the speedtest results</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            <p>Use the slider on the "Stream" settings page to set the target bitrate to the upload speed reported by the speed test.</p>
            <p>The app will choose an apporpriate resolution and framerate to stream at based on the target bitrate.</p>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary>Tap "Start Stream"</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            <p>Click "Start Stream" to start sending live video to YouTube. </p>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary>Tap "Go Live"</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
            <p>The Go Live button will be enabled once video is being sent to YouTube.  Click it to make your stream available to your viewers.</p>
            <p>Alternatively, you can configure your YouTube stream to auto start, so it goes live automatically when you begin broadcasting.  Note that 
                if you configure the stream this way, once you begin broadcasting you won't be able to restart the stream, so this mode shouldn't be 
                used for testing.
            </p>
            <p>You can also use the YouTube Studio site to Go Live once you begin streaming.  With this approach you will get confirmation from YouTube that 
                your stream is Live.
            </p>
            </AccordionDetails>
        </Accordion>
            
       
        
        
        </>
    )}