interface Props {
    setPage: (x:string) => void
}
export default function IssuesPage(props:Props) {
    return (
        <>
        <h3>Known Issues</h3>

        <p>SportStream Camera is currently in testing.  There are a number of known issues that we are working on that you should be aware of.</p>
        
        <p>If the app does crash, when you open it again you will be prompted to restore the last known state.  Selecting "Restore State" will have the app resume 
            streaming, and recover the teams, score, shots and period.


        </p>

        <ul>
            <li><b>1080p - Streaming and Recording: </b>Simultaneously streaming 1080p and recording crashes the app after a minute or two.  If you need to stream and record simultaneously, you will need to use 720p resolution for now.</li>
            <li><b>Theme color changing: </b>Setting theme colors occasionally causes the app to crash.</li>
            <li><b>Game Menu interruptions: </b>When in the game menu (for example setting a goal's score and assists, or setting a penalty) starting the game clock may skip a step, causing unexpected results.</li>
            <li><b>Game Menu lockout: </b>Occasionally, the game menu may become unresponsive (when using the game controller).  You may need to tap the back (B) button a few times before you can open the game menu (X button) </li>
            <li><b>Audio Monitoring delay: </b>There is a delay in the audio monitoring that may grow longer as you stream.  This delay only affects monitoring (listening to the sound via headphones) and does not affect the stream audio.</li>
        </ul>


        </>
    )
}