import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}
export default function SettingsPage(props:Props) {
    return (
        <>
        <h1>Settings</h1>
        <p>The SportStream Camera app opens directly to the camera view.   In order to access the settings, 
            tap twice on the gear icon in the top right corner of the screen.
        </p>
        <img src='images/camerascreenshot.PNG' style={{width:'50%', border:'2px solid black'}} />

        <p>The settings are divided into 8 tabs:
<ul>
    <li>Collections</li>
    <li>Teams</li>
    <li>Theme</li>
    <li>Stream</li>
    <li>Camera</li>
    <li>Audio</li>
    <li>Options</li>
    <li>Controls</li>
</ul>

        </p>


        <img src='images/settingsscreenshot.PNG' style={{width:'50%', border:'2px solid black'}} />

        </>
    )}