import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material"

interface Props {
    setPage: (x:string) => void
}
export default function StreamingPage(props:Props) {
    return (
        <>
        
        <p>SportStream Camera streams live video using the RTMP protocol.  Any service that accepts RTMP or RTMPS streams should 
            work with the app.
        </p>

        <p>RTMP typically requires a URL to stream to, and a stream key to identify the stream.  The RTMP/RTMPS url and stream key provided 
            by the streaming service can be copied into the text inputs on the Stream page.
        </p>

        <p>SportStream Camera integrates with YouTube and Facebook to stream to those platforms, and also provides a manual streaming mode ("Other" in the screenshot below) to stream to
other platforms that support RTMP/RTMPS streaming.
        </p>
        <p>The target bitrate slider is used to control the stream quality - it should be set in cosideration of your available upload speed.  As you increase the target bitrate, 
            the app will adjust the resolution and framerate automatically, showing those values directly underneath the slider.  The approximate data usage per hour will be displayed
             on the screen as well.
        </p>
        <p>If the target bitrate resolves to a resulution or framerate that is more than the current camera settings, a warning will appear on the screen indicating so.</p>

        
        <img src='images/stream.png' style={{width:'50%', border:'2px solid black'}}/>

<hr/>

        <Accordion>
            <AccordionSummary>YouTube Streaming</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
<p>When you select the "YouTube" platform from the prompt when opening the Stream page, the app will prompt you to log in to your YouTube (Google) account.  Once you log in,
    the app will retrieve your upcoming scheduled streams and add them to the list.  Any live streams will also be added to the bottom of the list - this is to allow you to resume streaming
    to an already-live stream in a new app session.</p>

    <p>Selecting a stream from the list will load the RTMP URL and Stream Key into the fields on the right side of the screen.</p>
    
    <h4>Starting a live YouTube broadcast</h4>
        <p>To start broadcasting to YouTube, you can select the stream from the list, set the bitrate, and tap the "Go Live" button.  The app will begin broadcasting video to 
            YouTube, then wait for the YouTube system to confirm that the feed is ready to go live, then prompt you to confirm going Live.  This process takes several seconds as
            YouTube needs to validate that the video data coming in can be used for the stream.   The status of the app's communication with YouTube is displayed under teh control buttons.
        </p>
        <p>You can also manually control the YouTube feed as well, by using the "Start Stream" button to start sending video to YouTube, but
            not make the feed live until you click the "Go Live" button.  This is a useful option if you prefer to montior the feed through YouTube studio
            before it goes live.  You can also go live directly from YouTube studio after tapping the "Start Stream" button.
        </p>
        <h4>Stopping a YouTube broadcast</h4>
        <p>As with starting a YouTube broadcast, you can stop sending video and end the Live Broadcast with the "End Stream" button, or you can stop sending data but
            keep the stream live by hitting "Stop Stream".  You can also stop broadcasting video by simply closing the app, but this will leave the 
            YouTube feed live (with no video) until it is ended (via the app, or via YouTube Studio).  You may want to end the live feed on YouTube to 
            see the analytics, for example.
        </p>
        <img src='images/youtubeselect.png' style={{width:'50%', border:'2px solid black'}}/>


                </AccordionDetails></Accordion>

                <Accordion>
            <AccordionSummary>Facebook Streaming</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>

        <b><i>Please note:  SportStream Camera is currently going through a verification process with Facebook.  Until verified, the app will only be allowed to stream to personal Facebook pages (not group pages).
            It is still possible to use the manual stream process to live stream to Facebook Groups by setting up the stream on FaceBook and manually entering the RTMP URL and Stream Key provided by Facebook.
            </i></b>

<p>After choosing the "Facebook" platform from the prompt when you open the Stream screen, the app will prompt you to log in via Facebook.</p>
<p>Once logged in, you will have the option to "Go Live on My Page" to start a live stream on your personal Facebook page. </p>
<p>The video name will use the current date and the team names - for example "2024/05/01 Rockets vs Ravens."  You will want to set the teams in the app before
    going Live on Facebook to make sure the video title shows the correct teams.
</p>
<p>When going live on Facebook, the app will prompt you to "Go Live - Public" or "Go Live - Private".  If you choose Private, only you will be able to see the live feed.  This is a good option for testing.
If you choose Public, anyone with access to your Facebook page will be able to watch the stream.

</p>


            <img src='images/facebookselect.png' style={{width:'50%', border:'2px solid black'}}/>
            <br/>
            <img src='images/facebookgolive.png' style={{width:'50%', border:'2px solid black'}}/>



                </AccordionDetails></Accordion>

                <Accordion>
            <AccordionSummary>Manual Streaming</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
                <p>You can stream to any RTMP server using SportStream Camera, as long as you have the URL to send data to, and the stream key to use.</p>
                <p>Most platforms will provide this information to you when setting up the feed.  Please refer to the platform documentation for whatever system you want to broadcast to for more details.</p>
                


            <img src='images/manualstart.png' style={{width:'50%', border:'2px solid black'}}/>

                </AccordionDetails></Accordion>

<Accordion>
            <AccordionSummary>Stream Status Indicator</AccordionSummary>
            <AccordionDetails style={{backgroundColor:'#dddddd'}}>
        
        <p>While streaming, the Stream status indicator will turn white, and the square in the status area will indicate the connection quality. 
            A green square indicates that everything is good.  The square will shift towards red if the app is unable to maintain the 
            video upload rate.
        </p>
        <i>Good Stream Quality</i><br/>
    <img src='images/status_stream.png' /><hr/>
    <i>Fair Stream Quality</i><br/>
    <img src='images/status_fair.png' /><hr/>
    <i>Poor Stream Quality</i><br/><img src='images/status_poor.png' />
        
        <p></p>

        <p>The app will attempt to maintain the upload rate set in the Stream page.  If it is unable to maintain that rate, the quality will be reduced 
            to lower the amount of data sent.  If the quality has been reduced, the app will try to slowly increase the quality until it reaches the target 
            bitrate, or until it has maximized the available upload bandwidth.
        </p>

        <p>If the app is unable to continue streaming (due to poor or changing network conditions) the Stream indicator will be outlined in yellow.  This indicates 
            that the app is trying to reconnect the live stream. </p>
       
       <img src="images/status_reconnect.png" />

       </AccordionDetails></Accordion>
        </>

    )}