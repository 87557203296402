interface Props {
    setPage: (x:string) => void
}
export default function OptionsPage(props:Props) {
    return (
        <>
        <p>Each Scene Collection will have it's own set of options to customize the behaviour during streaming.  The available options will vary with each Scene Collection.</p>
        
        <p>For example, the "Ringette" scene collections options include:
<ul>
    <li>Delete replay files - <i>If enabled, instant replay videos will be deleted once they are played.  If disabled, all replays 
        will be saved as videos in the iOS Files app, under the SportStream Camera folder.</i></li>
        <li>Reset Shot Clock on Clock Start/Stop - <i>Will reset the shot clock when the main game clock is stopped/started</i></li>
<li>Reset Shot Clock on Shot Counted - <i>Will reset the shot clock when a shot is tracked.</i></li>
<li>Replay Sime in Seconds - <i>The length (in real time) of the instant replay.  Note that replays run in slow motion, so the actual time 
    the replay shows on screen will be twice as long as this value.</i></li>

</ul>

        </p>
        
        </>
    )}