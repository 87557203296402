
interface Props {
    setPage: (x:string) => void
}
export default function PrivacyPage(props:Props) {
    return (
        <>
        <h1>Privacy Policy for "SportStream Camera" (iOS)</h1>

		<h2>Introduction</h2>
		<p>This privacy policy governs your use of the software application "Camera Streaming App" for iOS devices. This app provides a full-screen camera preview to use as a video source for streaming.</p>
	<h2>Collection and Use of Personal Information</h2>
    <p>We do not collect or store any personal information about you, including your name, address, email, or phone number. The app does request access to your device's photo library in order to customize the user interface with team logos. The app only accesses the camera and microphone on your device to provide the live video stream.</p>
	<h2>Collection and Use of Non-Personal Information</h2>
    <p>We may collect and use non-personal information, such as device type, operating system, and app usage data, to improve the app and provide better service to our users. This information is collected through third-party analytics services.</p>
	<h2>Third-Party Services</h2>
    <p>We use third-party services to provide video streaming and analytics services. These third-party services may collect and use personal and non-personal information as described in their own privacy policies. We encourage you to review the privacy policies of these third-party services before using the app.</p>
    <blockquote><h2>Google/YouTube Services</h2>
    <p>The app utilizes YouTube APIs provided by Google to authorize your Google Account, and:
        <ul>
            <li>Retrieves and uses an authentication token to make additional calls to YouTube services</li>
            <li>Accesses information about your YouTube channel broadcasts and live streams, including stream keys, titles, times and descriptions of your upcoming and current broadcasts</li>
            <li>Starts and ends live broadcasts</li>
        </ul>
        </p>
        <p>
        Information retrieved from Google, including that mentioned above,  may be stored on your device temporarily to recover from crashes and resume live streaming when the app is restarted.  When the app is closed normally,
        stored information is removed from the device.  You must re-authenticate to YouTube every app session.
    </p>
    <p>None of the information retrieved from your Google Account is shared with any party, including the SportStream Camera development team.  The </p>
    <p>SportStream Camera's use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
    </blockquote>
	<h2>Security</h2>
    <p>We are committed to protecting the security of your personal and non-personal information. We use commercially reasonable measures to protect against unauthorized access, use, or disclosure of your information. However, no data transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee the security of your information.</p>
	<h2>Changes to Privacy Policy</h2>
    <p>We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. Your continued use of the app constitutes acceptance of the updated privacy policy.</p>
	<h2>Contact Us</h2>
    <p>If you have any questions or concerns about our privacy policy or the way we collect and use information, please contact us at danimal.thorson@gmail.com.</p>
	<h2>Effective Date</h2>
    <p>This privacy policy is effective as of February 3, 2023</p>
        </>
    )}