import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}
export default function TeamsPage(props:Props) {
    return (
        <><h1>Teams and Rosters</h1>
        <p>This screen is used to add teams and players to the app.</p>
        <p>To add teams to the app, tap "Add New Team".  Enter the City/Division (optional) and the team name. 
        If you have two teams with the same name, the City/Division information is used to keep the rosters seperate.
        </p>
        <p>Once you add a team, a roster list will be added.  To add players to a team roster, select the roster and tap 
            "Add Player".  Enter the player number and name to add them to that roster.
        </p>
        <p>Player names are used to display things such as goals/scores, penalties, etc.</p>
        <p>You can add team logos to be displayed on the score board and intermission scenes - once a team has been selected 
            from the list, tap the "Choose Logo" button to select a team logo from your photo library.  The current team logo 
            will display for the selected team just below the team list. </p>

        <h3>Team Sharing</h3>
        <p>To share teams, rosters and logos between two phones, open the "Teams" screen on both phones, and tap the "Share" button to enable
            wireless sharing between the phones.   Once sharing has been enabled on both phones, select the team you would like to share
            from the first phone and tap "Share" again.  The second phone will prompt to allow the content transfer.  Once that is allowed, after a 
            few seconds to allow the phones to connect, click "Continue" on the first phone to share the team, roster and logo with the second phone.
        </p>
        
        <img src='images/teams_screenshot.png' style={{width:'50%', border:'2px solid black'}}  />
        </>
    )}