import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}
export default function RecordingPage(props:Props) {
    return (
        <>
        <p>To start / stop recording, go into the setup menu (via touch controls or game controller) and choose "Start Recording" or "Stop Recording".  
            When the app is recording, the recording status indicator will turn white.
        </p>
        <img src='images/status_record.png' />
        <p>The app records at the resolution and framerate that the camera is set to.</p>
        <p>Recorded video files can be found in the iOS Files app, in the "On My iPhone" location, under the SportStream Camera folder.</p>
        
        </>
    )}