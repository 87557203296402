import { Typography } from "@mui/material";

interface Props {
    setPage: (x:string) => void
}
export default function UpDeckPage(props:Props) {
    return (
        <>
        <p>SportStream Camera has limited support to interface with the <a href='https://8up.uk' target='_blank'>UPDeck</a> software  
         to control OBS.</p>

        <p>The configuration for UpDeck can be found in the iOS Settings app, under the SportStream Camera settings.</p>

        <p>The settings are:
            <ul>
                <li>Automatically connect to UPDeck - <i>If disabled, you must set a controller button mapping to connect to UPDeck</i></li>
                <li>Use USB connection for UPDeck - <i>Utilize the SportStream Camera Connection Agent (for MacOS) to connect to UPDeck, rather than connecting via IP address</i></li>
                <li>UPDeck Host/IP - <i>Address to connect to UPDeck on</i></li>
                <li>UPDeck Port - <i>TCP port tp use to connect to UPDeck</i></li>
                <li>UPDeck Password - <i>Password to authenticate to UPDeck with</i></li>
            </ul>
        </p>

        <p>SportStream Camera supports the following UPDeck functionality.
        <ul>
            <li>Sending hotkeys</li>
            <li>Hiding/Showing Sources</li>
            <li>Switching Scenes</li>
            <li>Setting source texts</li>
            <li>HTTP GET requests</li>
            <li>Incrementing/Decrementing UPDeck counters</li>

        </ul>


        </p>

        <p>With the exception of Switching Scenes, the above functionality must be mapped to controller buttons in the iOS Settings app under SportStream Camera</p>
       
        </>
    )}