

interface Props {
    setPage: (x:string) => void
}

export default function AgentPage(props:Props) {
    return (
        <>

<p>The Connection Agent will allow you to connect the App to an UPDeck instance by proxying the connection through the USB connection. 
    This will allow the app to communicate directly with UPDeck running on the same host as OBS, without needing to access UPDeck 
    via the network. 
</p>
<p>This connection provides a low latency, direct connection with UPDeck.  This is particularly useful when connected to public networks where the iPHone and OBS can 
    not communicate directly.
</p>
<p>To connect the Agent to UPDeck, enter the host/IP, port and UPDeck password, and click the “Connect To UPDeck” button. You should see the connection indicator on UPDeck turn from red to green, indicating that the Agent has successfully authenticated to UPDeck, and the “UPDeck Status” label should switch from “Disconnected” to “Connected”</p>

<p>When the app is started, if it is configured to connect to UPDeck automatically and via the USB interface, the “iPhone Status” label will switch from “Disconnected” to “Connected”. Otherwise, that label will switch when the “Connect to UPDeck” action is triggered.</p>

<img src="images/agent.png" />

<p>To download the applicaiton for MacOS, <a href='https://www.sportstreamcamera.com/SportStreamCamera_UPDeck_Agent.app.zip'>click here</a></p>
        </>
    )
}