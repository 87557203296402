import { Grid, Typography } from "@mui/material";
import { flexbox } from "@mui/system";

interface Props {
    setPage: (x:string) => void
}
export default function SpeedTestPage(props:Props) {
    return (<>
        <h1>Testing Speed</h1>
        <p>To maximize the stream quality, you will want to measure the upload 
            speed available and set the stream bitrate to that value.  </p>
            <p>You can use a speed test site such as <a href='https://speedtest.net' target='_blank'>Speedtest.net</a> or and app such 
            as <a href='https://apps.apple.com/us/app/speedtest-by-ookla/id300704847' target='_blank'>Ookla's SpeedTest</a>.  These will measure your download and upload speeds.
            </p>
            <p>For live streaming, you only need to worry about the upload speed.  The above tools will proved a measurement in Mpbs (megabits per second) 
                You can set the target bitrate in the Stream settings page at or below the reported upload speed.</p>
                <p>In the screenshot below, the upload speed is measured at 1.33 Mbps.  That is the value to set the bitrate to on the Stream page.</p>
<div style={{verticalAlign:'top'}}>
<img src='images/speedtest.jpeg' style={{width:'20%', border:'2px solid black'}} alt='Speedtest screenshot' />

    <img src='images/stream_screenshot.png' style={{width:'40%', border:'2px solid black'}} alt='Stream screenshot' />
</div>

        </>
    )}